import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import cropIcon from './crop.svg'

export default class StorehausTransformationUI extends Plugin {
  init () {
    this.editor.ui.componentFactory.add('storehausCrop', locale => {
      const command = this.editor.commands.get('storehausTransformationEditing')
      const view = new ButtonView(locale)

      view.set({
        label: 'Crop',
        icon: cropIcon,
        tooltip: true
      })

      view.bind('isEnabled').to(command, 'isEnabled')

      this.listenTo(view, 'execute', () =>
        this.editor.execute('storehausTransformationEditing')
      )

      return view
    })
  }
}
