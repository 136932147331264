import Command from '@ckeditor/ckeditor5-core/src/command'
import { isStorehausImage, getBlobId, getTransformation, updateImage } from './utils'
import { openCropper, buildCropperPath } from '../../storehaus/storehaus_transformation'
import { validateTransformation, validateSrc } from '../../storehaus/storehaus'

export default class StorehausTransformationCommand extends Command {
  refresh () {
    const element = this.editor.model.document.selection.getSelectedElement()

    this.isEnabled = isStorehausImage(element)
  }

  execute (_) {
    const model = this.editor.model
    const imageElement = model.document.selection.getSelectedElement()
    const cropperPath = buildCropperPath(getBlobId(imageElement),
      getTransformation(imageElement))
    this.transformationHandler = this.receiveData.bind(this)
    openCropper(cropperPath, this.transformationHandler)
  }

  receiveData (event) {
    if (event.origin !== window.location.origin) { return }

    if (!validateSrc(event.data)) {
      console.error('Did not receive new src for image.')
      return
    }
    if (!validateTransformation(event.data)) {
      console.error('Did not receive valid transformation for image.')
      return
    }
    window.removeEventListener('message', this.transformationHandler)

    const transformation = event.data.transformation
    const newSrc = event.data.newSrc
    const model = this.editor.model
    const imageElement = model.document.selection.getSelectedElement()

    updateImage(newSrc, transformation, imageElement, model)
  }
}
