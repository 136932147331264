import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import folderIcon from './folder.svg'

export default class StorehausPickerUI extends Plugin {
  init () {
    this.editor.ui.componentFactory.add('storehausPicker', locale => {
      const view = new ButtonView(locale)

      view.set({
        label: 'Browse...',
        icon: folderIcon,
        tooltip: true
      })

      this.listenTo(view, 'execute', () =>
        this.editor.execute('storehausPickerEditing')
      )

      return view
    })
  }
}
