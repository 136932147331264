import { objectToParameterString } from '../storehaus/storehaus'

export function openCropper (path, handler) {
  window.open(path)
  window.addEventListener('message', handler)
}

export function buildCropperPath (blobId, transformation, otherParams) {
  let params = objectToParameterString(
    Object.assign({}, transformation, otherParams)
  )
  return '/storehaus/cropper/' + blobId + '?' + params
}

export function updateCropLink(link, blobId, transformation) {
  const existingURL = new URL(link.href)
  const existingParams = Object.fromEntries(existingURL.searchParams.entries())
  const newPath = buildCropperPath(blobId, transformation, existingParams)

  link.href = newPath

  return newPath
}