import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'

// import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'

import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import List from '@ckeditor/ckeditor5-list/src/list'
import Link from '@ckeditor/ckeditor5-link/src/link'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed'

import ActiveStorageUpload from './active_storage_upload/active_storage_upload'

import StorehausPicker from './storehaus-ckeditor5/storehaus_picker'
import StorehausTransformation from './storehaus-ckeditor5/storehaus_transformation'

function logError (error) {
  console.error(error)
}

function handleEditorSuccess (editor) {
  console.log('Editor was initialized')
}

function createAdminEditor (textarea) {
  ClassicEditor
    .create(textarea, {
      plugins: [Essentials, Bold, Italic, Heading, BlockQuote, Paragraph, List,
        Link, Image, ImageCaption, ImageToolbar, ImageUpload, ImageStyle,
        ActiveStorageUpload, MediaEmbed, StorehausTransformation,
        StorehausPicker],
      toolbar: {

        items: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList',
          'bulletedList', '|', 'link', '|', 'imageUpload', 'mediaEmbed',
          'storehausPicker', '|', 'undo', 'redo'],
        viewportTopOffset: 84
      },
      image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:full',
          'imageStyle:side', 'storehausCrop']
        // toolbar: [ 'imageTextAlternative', '|', 'imageStyle:full', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight' ]
      },
      ActiveStorageUpload: {
        directUploadUrl: '/rails/active_storage/direct_uploads',
        inlineFilesField: 'body_inline_files[]'
      },
      storehausPicker: {
        pickerUrl: '/storehaus/picker'
      }
    })
    .then(handleEditorSuccess)
    .catch(logError)
}

function createUserEditor (container) {
  ClassicEditor
    .create(container, {
      plugins: [Essentials, Bold, Italic, Heading, BlockQuote, Paragraph, List,
        Link, Image, ImageCaption, ImageToolbar, ImageUpload, ImageStyle,
        ActiveStorageUpload, MediaEmbed, StorehausTransformation],
      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList',
        'bulletedList', '|', 'link', '|', 'imageUpload', 'embedMedia', '|',
        'undo', 'redo'],
      image: {
        toolbar: ['imageTextAlternative', '|', 'imageStyle:full',
          'imageStyle:side', 'storehausCrop']
      },
      ActiveStorageUpload: {
        directUploadUrl: '/rails/active_storage/direct_uploads',
        inlineFilesField: 'body_inline_files[]'
      },
      storehausPicker: {
        pickerUrl: '/storehaus/picker'
      }
    })
    .then(handleEditorSuccess)
    .catch(logError)
}

function forEachElement (nodeSet, fn) {
  if (typeof (nodeSet) === 'string') { nodeSet = document.querySelectorAll(nodeSet) }

  Array.prototype.forEach.call(nodeSet, fn)
}

function ready () {
  const adminEditors = document.querySelectorAll('textarea.ckeditor5')
  forEachElement(adminEditors, createAdminEditor)
}

window.createUserEditor = createUserEditor
window.forEachElement = forEachElement

document.addEventListener('DOMContentLoaded', ready)
