export function validateTransformation (eventData) {
  if (eventData.transformation == undefined) { return false }
  return (validateCropMetrics(eventData.transformation.crop))
}

export function validateSrc (eventData) {
  return (eventData.newSrc !== undefined && eventData.newSrc !== '')
}

export function validateCropMetrics (cropMetrics) {
  const requiredKeys = ['w', 'h', 'x', 'y']
  return requiredKeys.every(key => cropMetrics.hasOwnProperty(key))
}

export function objectToParameterString (obj, prefix) {
  var str = []
  var p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p
      var v = obj[p]

      str.push((v !== null && typeof v === 'object')
        ? objectToParameterString(v, k)
        : encodeURIComponent(k) + '=' + encodeURIComponent(v))
    }
  }
  return str.join('&')
}
