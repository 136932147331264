import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import FileRepository from '@ckeditor/ckeditor5-upload/src/filerepository'
import ActiveStorageUploadAdapter from './active_storage_upload_adapter'

export default class ActiveStorageUpload extends Plugin {
  static get requires() {
    return [FileRepository]
  }

  static get pluginName() {
    return 'ActiveStorageUpload'
  }

  init() {
    const directUploadUrl = this.editor.config.get('ActiveStorageUpload.directUploadUrl')

    if (!directUploadUrl) {
      console.warn('ActiveStorageUpload.directUploadUrl is not configured')
      return
    }

    this.editor.plugins.get('FileRepository').createUploadAdapter = loader => new ActiveStorageUploadAdapter(loader, directUploadUrl)
  }
}
