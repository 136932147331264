import { openCropper, updateCropLink } from './storehaus_transformation'
import { openPicker } from './storehaus_picker'
import { validateTransformation, validateSrc } from './storehaus'

function handleTransformationData (event) {
  console.log(event.data)

  if (event.origin !== window.location.origin) { console.log('ignore wrong origin'); return }
  if (!validateSrc(event.data)) {
    console.error('Did not receive new src for image.')
    return
  }

  window.removeEventListener('message', this.handleTransformationData)
  if (!validateTransformation(event.data)) {
    console.error('Did not receive valid transformation for image.', event.data)
    return
  }
  const blobId = event.data.blobId
  const newSrc = event.data.newSrc
  const transformation = event.data.transformation

  updateStorehausWidget(this, blobId, newSrc, transformation)
}

function handlePickerData (event) {
  if (event.origin !== window.location.origin) { return }

  window.removeEventListener('message', this.handlePickerData)

  if (!validateSrc(event.data)) {
    console.error('Did not receive new src for image.')
    return
  }

  const blobId = event.data.blobId
  const newSrc = event.data.newSrc

  updateStorehausWidget(this, blobId, newSrc, {})
}

function updateStorehausWidget (container, blobId, newSrc, transformation) {
  const thumbnail = container.querySelector('img.storehaus-thumbnail')
  const cropLink = container.querySelector('a.storehaus-action.crop')
  const removeLink = container.querySelector('a.storehaus-action.remove')

  const idField = container.querySelector('input.storehaus-field.blob-id')
  const transformationField = container.querySelector('input.storehaus-field.transformation')

  idField.value = blobId
  updateCropLink(cropLink, blobId, transformation)
  transformationField.value = JSON.stringify(transformation)

  cropLink.style.visibility = 'visible'
  removeLink.style.visibility = 'visible'

  thumbnail.src = newSrc
}

function flagForRemoval (event) {
  event.preventDefault()
  event.stopPropagation()

  const destroyField = this.querySelector('input.storehaus-field.destroy')

  destroyField.value = 1
  this.style.opacity = 0.3
  this.unflagForRemoval = unflagForRemoval.bind(this)
  this.addEventListener('click', this.unflagForRemoval)
}

function unflagForRemoval (event) {
  this.removeEventListener('click', this.unflagForRemoval)
  event.preventDefault()
  event.stopPropagation()

  const destroyField = this.querySelector('input.storehaus-field.destroy')

  this.style.opacity = 1
  destroyField.value = ''
}

document.addEventListener('DOMContentLoaded', () => {
  forEachElement('a.storehaus-action.crop', (cropLink) => {
    cropLink.addEventListener('click', (event) => {
      event.preventDefault()

      const container = event.currentTarget.parentElement
      container.handleTransformationData = handleTransformationData.bind(container)

      openCropper(event.currentTarget.href, container.handleTransformationData)
    })
  })

  forEachElement('a.storehaus-picker', (pickerLink) => {
    pickerLink.addEventListener('click', (event) => {
      event.preventDefault()

      const container = event.currentTarget.parentElement
      container.handlePickerData = handlePickerData.bind(container)

      openPicker(event.currentTarget.href, container.handlePickerData)
    })
  })

  forEachElement('a.storehaus-action.remove', (removeLink) => {
    removeLink.addEventListener('click',
      flagForRemoval.bind(removeLink.parentElement))
  })
})

function forEachElement (nodeSet, fn) {
  if (typeof (nodeSet) === 'string') { nodeSet = document.querySelectorAll(nodeSet) }

  Array.prototype.forEach.call(nodeSet, fn)
}
