import StorehausPickerCommand from './storehaus_picker_command'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class StorehausPickerEditing extends Plugin {
  init () {
    const editor = this.editor

    editor.commands.add('storehausPickerEditing',
      new StorehausPickerCommand(this.editor))
  }
}
