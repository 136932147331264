import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import StorehausPickerCommand from './storehaus_picker_command'
import StorehausPickerEditing from './storehaus_picker_editing'
import StorehausPickerUI from './storehaus_picker_ui'

export default class StorehausPicker extends Plugin {
  static get requires () {
    return [StorehausPickerCommand, StorehausPickerEditing, StorehausPickerUI]
  }

  static get pluginName () {
    return 'StorehausPicker'
  }
}
