import { DirectUpload } from 'activestorage'

export default class ActiveStorageUploadAdapter {
  constructor(loader, directUploadUrl) {
    this.loader = loader
    this.directUploadUrl = directUploadUrl
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this.directUpload = new DirectUpload(file, this.directUploadUrl, this)

        this.directUpload.create((error, blob) => {
          if (error) {
            reject(this.t('Upload failed'))
          } else {
            resolve({
              default: this._railsBlobPath(blob.signed_id, blob.filename)
            })
          }
        })
      })
      )
  }

  abort() {
    if (this.directUpload.upload) {
      console.log('aborted upload')
      this.directUpload.upload.xhr.abort()
    }
  }

  _railsBlobPath(signedId, fileName) {
    // TODO: Get this from backend or at least configurable(?)
    return '/rails/active_storage/blobs/' + signedId + '/' + fileName
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress',
      event => this.directUploadDidProgress(event)
    )
  }

  directUploadDidProgress(event) {
    this.loader.uploaded = event.loaded
    this.loader.uploadTotal = event.total
  }
}
