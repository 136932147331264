import { isImage } from '@ckeditor/ckeditor5-image/src/image/utils'
import { validateTransformation } from '../../storehaus/storehaus'

export function isStorehausImage (element) {
  return (isImage(element) && getBlobId(element))
}

export function getBlobId (element) {
  var source = element.getAttribute('src')

  if (!source) {
    return false
  }

  var matches = source.match(/\/rails\/active_storage\/(?:blobs|representations)\/([^/]+)\//)
  if (!matches) {
    return false
  }

  return matches[1]
}

export function getTransformation (imageElement) {
  var raw = imageElement.getAttribute('data-storehaus-transformation')
  if (raw === undefined) return {}

  return JSON.parse(raw)
}

export function setTransformation (transformation, imageElement, model) {
  model.change(writer => {
    writer.setAttribute(
      'data-storehaus-transformation',
      JSON.stringify(transformation),
      imageElement
    )
  })
}

export function insertImage (newSrc, transformation, model) {
  model.change(writer => {
    const imageElement = writer.createElement('image', {
      src: newSrc
    })

    if (validateTransformation(transformation, false)) {
      writer.setAttribute(
        'data-storehaus-transformation',
        JSON.stringify(transformation),
        imageElement)
    }

    model.insertContent(imageElement, model.document.selection)
  })
}

export function updateImage (newSrc, transformation, imageElement, model) {
  setTransformation(transformation, imageElement, model)
  updateImageSrc(newSrc, imageElement, model)
}

export function updateImageSrc (newSrc, imageElement, model) {
  model.change(writer => {
    writer.setAttribute('src', newSrc, imageElement)
  })
}
