import StorehausTransformationCommand from './storehaus_transformation_command'
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

export default class StorehausTransformationEditing extends Plugin {
  init() {
    const editor = this.editor

    editor.model.schema.extend('image', {
      allowAttributes: ['data-storehaus-transformation']
    })

    editor.conversion.attributeToAttribute({
      model: 'data-storehaus-transformation', view: 'data-storehaus-transformation'
    })

    editor.commands.add('storehausTransformationEditing',
      new StorehausTransformationCommand(this.editor))
  }
}
