import Command from '@ckeditor/ckeditor5-core/src/command'
import { insertImage } from './utils'
import { openPicker } from '../../storehaus/storehaus_picker'
import { validateSrc } from '../../storehaus/storehaus'

export default class StorehausPickerCommand extends Command {
  execute (_) {
    const pickerPath = this.editor.config.get('storehausPicker.pickerUrl')

    this.pickerHandler = this.receiveData.bind(this)

    console.log(this.pickerHandler)
    openPicker(pickerPath, this.pickerHandler)
  }

  receiveData (event) {
    if (event.origin !== window.location.origin) { return }
    console.log(this.pickerHandler)

    if (!validateSrc(event.data)) {
      console.error('Did not receive new src for image.')
      return
    }

    window.removeEventListener('message', this.pickerHandler)

    const transformation = event.data.transformation
    const newSrc = event.data.newSrc
    const model = this.editor.model

    insertImage(newSrc, transformation, model)
  }
}
