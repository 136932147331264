import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import StorehausTransformationCommand from './storehaus_transformation_command'
import StorehausTransformationEditing from './storehaus_transformation_editing'
import StorehausTransformationUI from './storehaus_transformation_ui'

export default class StorehausTransformation extends Plugin {
  static get requires () {
    return [StorehausTransformationCommand, StorehausTransformationEditing, StorehausTransformationUI]
  }

  static get pluginName () {
    return 'StorehausTransformation'
  }
}
